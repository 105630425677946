import React from 'react';
import Api from '../../Services/api';
import Login from '../auth/Login';
import withRouter from '../../Services/withRouter';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Offcanvas from "react-bootstrap/Offcanvas";
import Button  from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';

class Cart extends React.Component{


    componentDidMount = async()=>{
        await this.verifyUser();
        await this.getCart();
        await this.getCoupons();
        await this.props.fetchCart();
    }



    constructor(props){
        super(props);
        this.state = {
            'cart' : [],
            'total_amount' : 0,
            'total_discount' : 0,
            'total_price' : 0,
            'coupon_discount' : 0,
            'show_login' : false,
            'coupons' : [],
            'coupon' : {},
            'show_coupon' : null,
            'show_coupons' : false,
            "coupon_discount" : 0,
            "show_coupon_modal" : false,
            'shipping_charge' : 0,
            'shipping_discount_min_price' : 0,
            'coupon_discount_min_price' : 0,
            "stock_error" : false
        }
    }



    getCart = async()=>{
        var response = await Api.GetRequest({}, 'cart');
        if(response.status == 200){
            this.setState({
                'cart' : response.cart,
                'total_discount' : response.total_discount,
                'total_amount' : response.total_amount,
                'total_price' : response.total_price,
                'coupon' : response.coupon,
                'shipping_charge' : response.shipping_charge,
                'shipping_discount_min_price' : response.shipping_discount_min_price,
                'coupon_discount_min_price' : response.coupon_discount_min_price
            },()=>{
                this.calculateCouponDiscount();
            });
            
        }
        else{
            toast.error("Something went wrong. Please try again later.");
        }
    }


    getCoupons = async()=>{
        var response = await Api.GetRequest({}, 'coupons');
        if(response.status == 200){
            this.setState({'coupons' : response.coupons});
        }
        else{
            toast.error("Something went wrong to fetch the coupons. Please try again later");
        }
        
    }


    removeProduct = async(cart_id)=>{
        var data = {
            'cart_id' : cart_id
        }
        
        var response = await Api.PostRequest(data, 'delete-from-cart');
        if(response.status == 200){
            toast.success("Prdouct removed from your cart successfully.")
            this.getCart();
            this.props.fetchCart()
        }
        else{
            toast.error("Something went wrong. Please try again later.");
        }
    }
    

    verifyUser = async()=>{
        console.log(this.props)
        var response = await Api.GetRequest({}, 'verify-user');
        if(response.status == 401){
            this.setState({'show_login' : true});
        }   
        else{
            // this.props.navigate("/checkout");
        } 
    }


    handleLogin = async()=>{
        this.setState({'show_login' : false});
        this.props.setUserLogIn(true);
        // this.props.navigate("/checkout");
        this.getCart();
        this.props.fetchCart();
    }



    changeQuantity = async(status, cart_id)=>{
        var data ={
            'status' : status,
            'cart_id' : cart_id
        }
        var response = await Api.PostRequest(data, 'change-quantity');
        if(response.status == 200){
            this.getCart()
        }
        else{
            toast.error(response.message);
        }
    }


    setCoupon = async(coupon)=>{
        this.setState({'show_coupons' : false});
        var data = {
            'id' : coupon.id
        }
        var response = await Api.GetRequest(data, 'coupon');
        if(response.status == 200){
            await this.setState({
                'coupon' : response.coupon
            }, ()=>{
                this.calculateCouponDiscount();
            });

            this.setState({'show_coupon_modal' : true});
        }
        else{
            toast.error(response.message);
        }

        
    }



    removeCoupon = async()=>{
        var response = await Api.GetRequest({}, 'remove-coupon');
        if(response.status == 200){
            await this.setState({
                'coupon' : {},
                'coupon_discount' : 0,
            });
        }
        else{
            toast.error("Something went wrong. Please try again later.");
        }
    }



    calculateCouponDiscount = async()=>{
        var discount = 0;
        if(Object.keys(this.state.coupon).length > 0){
            if(this.state.coupon.discount_type == 0){
                discount = this.state.coupon.discount; 
            }
            else{
                discount = this.state.total_amount * this.state.coupon.discount / 100;    
            }
        }
        else{
            discount = 0;
        }
        this.setState({'coupon_discount' : discount});
    } 



    checkStock = async() =>{
        var response = await Api.GetRequest({}, 'check-stock');
        if(response.status == 200){
            this.props.navigate("/checkout");
        }
        else if(response.status == 406){
            this.setState({'stock_error' : true});
        }
        else{
            toast.error(response.message);
        }
    }



    render() {
        return(
            <>

                <Modal show={this.state.show_coupon_modal} backdrop centered>
                    <Modal.Header style={{'background' : '#FFFFFF', border: 0}}>
                        <Modal.Title className='w-100 justify-content-center d-flex align-items-center' style={{'background' : '#FFFFFF'}}>
                            <img src="assets/images/gift_box.gif" style={{height : '155px', background:'#FFFFFF', position:'absolute', objectFit:'contain', borderRadius : '100%', width:'155px'}}/>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='justify-content-center d-flex align-items-center d-flex flex-column'>
                        <h6 className='text-center mt-2'>'{this.state.coupon.code}' Applied</h6>
                        <h2 style={{'fontWeight' : 'bold'}} className='mb-0 mt-2 pt-2'> ₹ {this.state.coupon_discount}</h2>
                        <small style={{'fontWeight' : 'bold'}} className='mt-1 mb-5'>Saving with this coupon</small>
                    </Modal.Body>
                    <Modal.Footer className='justify-content-center'>
                        <a onClick={()=>{this.setState({'show_coupon_modal' : false})}} style={{'color' : '#FF6A00 !important'}} class="remove-from-cart" href="javascript:;" data-toggle="tooltip" title="" data-bs-original-title="Remove item" aria-label="Remove item">Yay !</a>
                    </Modal.Footer>
                </Modal>


                <Modal show={this.state.stock_error} backdrop centered>
                    <Modal.Title className='w-100 justify-content-center d-flex align-items-center' style={{'background' : '#FFFFFF'}}>
                        <img src="assets/images/info_error.png" style={{height : '125px', background:'#FFFFFF', position:'absolute', objectFit:'contain', borderRadius : '100%', width:'125px'}}/>
                    </Modal.Title>
                    <Modal.Body className='justify-content-center d-flex align-items-center d-flex flex-column'>
                        <div style={{'justifyContent' : 'center', 'display' : 'flex',  flexDirection : 'column', 'alignItems' : 'center'}} className='mt-5 pt-2'>
                            <h6 style={{'textAlign' : 'center'}} className='mb-0'>Some products in your cart is out of stock</h6>
                            <h6 style={{'textAlign' : 'center'}} className='mb-0 mt-0'> or </h6> 
                            <h6 style={{'textAlign' : 'center'}} className='mb-0 mt-0'>product quantity is not available. </h6>
                            <h6 style={{'textAlign' : 'center'}} className='mt-4 mb-0'>Please remove them from your cart.</h6>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className='justify-content-center'>
                        <a class="btn btn-primary" href="javascript:;" onClick={()=>{this.setState({'stock_error' : false})}} ><span>Continue</span></a>
                    </Modal.Footer>
                </Modal>

                <Offcanvas
                    style={{ zIndex: "10000", width: "35vw" }}
                    placement={"end"}
                    show={this.state.show_coupons}
                    onHide={() => {
                        this.setState({'show_coupons' : false})
                    }}
                >
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title className='mb-0'>
                                <h6 className='mb-0'>Coupons</h6>
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            {this.state.coupons.map((coupon, index)=>{
                                return(

                                    <div className='card my-5' style={{'border' : '1px dashed #000000', cursor : "pointer"}}>
                                        <div className='w-100 card-header p-2 justify-content-between d-flex align-items-center flex-row'>
                                            <div className='flex-column d-flex'>
                                                <b onClick={()=>{this.setState({'show_coupon' : index})}}>{coupon.code}</b>
                                                <small>{coupon.description}</small>
                                            </div>
                                            
                                            <Button onClick={()=>{this.setCoupon(coupon)}}  className="btn btn-primary"><span>Apply</span></Button>
                                        </div>

                                        {this.state.show_coupon == index &&
                                            <div className='card-body'>
                                                {coupon.description}
                                                <div dangerouslySetInnerHTML={{ __html: coupon.terms_conditions }}></div>
                                            </div>
                                        }
                                        
                                    </div>
                                    // <Accordion.Item eventKey={index} flush={true}>
                                    //     <Accordion.Header>
                                    //         <div className='w-100 justify-content-between d-flex align-items-center flex-row'>
                                    //             {coupon.code}
                                    //             <Button style={{'position' : 'absolute', right:0, zIndex:1000}} className="btn btn-primary"><span><i class="icon-arrow-left"></i> Apply</span></Button>
                                    //         </div>
                                    //     </Accordion.Header>
                                    //     <Accordion.Body>
                                    //         {coupon.description}
                                    //         <div dangerouslySetInnerHTML={{ __html: coupon.title }}></div>
                                    //     </Accordion.Body>
                                    // </Accordion.Item>
                                )
                            })}
                        </Offcanvas.Body>
                </Offcanvas>

                <Login show_login={this.state.show_login} handleLogin={this.handleLogin}></Login>
                
                {this.props.is_logged_in == true &&
                    <>
                        {this.state.cart.length != 0 &&
                            <div class="container padding-bottom-3x mb-1">
                                <div id="view_cart_load">
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div class="card cart-table">
                                                <div class="card-body">
                                                    <div class="table-responsive shopping-cart">
                                                        <table class="table table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th>Product</th>
                                                                    <th>Price</th>
                                                                    <th>Color</th>
                                                                    <th>Size</th>
                                                                    <th>Quantity</th>
                                                                    <th class="text-center">Discount</th>
                                                                    <th class="text-center">Subtotal</th>
                                                                    <th class="text-center px-5"></th>
                                                                </tr>
                                                            </thead>
    
                                                            <tbody id="cart_view_load">
                                                                {this.state.cart.map((product, index)=>{
                                                                    return(
                                                                        <tr key={index}>
                                                                            <td>
                                                                                <div class="product-item">
                                                                                    <Link to={`/product/product-details/${product.product_id}`}  style={{'verticalAlign' : 'middle'}} href="javascript::void(0)">
                                                                                        <img style={{width:'100px', height:'100px', objectFit:'contain'}} src={process.env.REACT_APP_MEDIA_URL + product.image } alt="Product"/>    
                                                                                    </Link>
                                                                                    <Link to={`/product/product-details/${product.product_id}`} class="product-info" style={{'verticalAlign' : 'middle'}}>
                                                                                        <h4 class="product-title" style={{'textAlign' : 'left', marginLeft:'10px'}}>{product.name}</h4>
                                                                                    </Link>
                                                                                </div>
                                                                            </td>
    
                                                                            <td class="text-center text-lg">₹ {product.price}</td>
    
                                                                            <td class="text-center text-lg">{product.color}</td>
    
                                                                            <td class="text-center text-lg">{product.size}</td>
    
                                                                            <td>
                                                                                <div class="form-group mb-0 justify-content-center align-items-center d-flex flex-column">
                                                                                    <div class="qtySelector product-quantity">
                                                                                        <span class="decreaseQty" onClick={()=>{this.changeQuantity(0, product.id)}}><i class="fas fa-minus"></i></span>
                                                                                        <input type="text" class="form-control qtyValue cart-amount" value={product.quantity}/>
                                                                                        <span class="increaseQty" onClick={()=>{this.changeQuantity(1, product.id)}}><i class="fas fa-plus"></i></span>
                                                                                    </div>
                                                                                    {(product.quantity > product.max_quantity && product.max_quantity != 0) &&
                                                                                        <small style={{'color' : 'red', marginTop:'10px', 'textAlign' : 'center'}}>Only {product.max_quantity} left</small>
                                                                                    }
                                                                                
                                                                                    {(product.max_quantity == 0) &&
                                                                                        <small style={{'color' : 'red', marginTop:'10px', 'textAlign' : 'center'}}>Out of stock</small>
                                                                                    }
                                                                                </div>
                                                                            </td>
    
                                                                            <td class="text-center text-lg">{product.discount} %</td>
    
                                                                            <td class="text-center text-lg">₹ {(product.price - product.price * product.discount /100).toFixed(0)}</td>
    
                                                                            <td class="text-center"><a onClick={()=>{this.removeProduct(product.id)}} class="remove-from-cart" href="javascript:;" data-toggle="tooltip" title="" data-bs-original-title="Remove item" aria-label="Remove item"><i class="icon-x"></i></a></td>
                                                                        </tr>
                                                                    )
                                                                })
                                                                
                                                                }
    
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>

                                            {this.state.cart.map((product, index)=>{
                                                return(
                                                    <div className='card m-2 cart-mobile-table'>
                                                        <div className='card-body'>
                                                            <div className='w-100 justify-content-start d-flex align-items-flex-start flex-row'>
                                                                <Link to={`/product/product-details/${product.product_id}`}  style={{'verticalAlign' : 'middle', color:'#FF6A00 !important'}} href="javascript::void(0)">
                                                                    <img style={{'width':'100px', 'height':'100px', 'objectFit':'contain'}} src={process.env.REACT_APP_MEDIA_URL + product.image } alt="Product"/>    
                                                                </Link>
                                                                <div className="w-100 justify-content-center d-flex align-items-baseline flex-column">
                                                                    <Link to={`/product/product-details/${product.product_id}`} class="product-info" style={{'verticalAlign' : 'middle', color:'#FF6A00 !important'}}>
                                                                        <p style={{'color' : '#FF6A00'}} className="product-title">{product.name}</p>
                                                                    </Link> 

                                                                    <div style={{'width' : '100%'}}  className='my-2 justify-content-start d-flex align-items-center flex-row flex-wrap'>
                                                                        {product.color &&
                                                                            <div className='px-1 py-2 mx-1 my-1' style={{'background' : '#f3f5f6'}}>
                                                                                <label style={{'fontSize' : '12px', 'fontWeight' : 'bold', 'textAlign' : 'left'}}>Color : </label>
                                                                                <span>&emsp;{product.color}</span> 
                                                                            </div>
                                                                        }
                                                                    
                                                                        
                                                                        {product.size &&
                                                                            <div className='px-1 py-2 mx-1 my-1' style={{'background' : '#f3f5f6'}}>
                                                                                <label style={{'fontSize' : '12px', 'fontWeight' : 'bold', 'textAlign' : 'left'}}>Size : </label>
                                                                                <span>&emsp;{product.size}</span> 
                                                                            </div>
                                                                        }

                                                                        <div class="form-group mb-0 my-1 justify-content-center align-items-center d-flex flex-column">

                                                                            <div className='px-2 d-flex flex-column py-2 mx-1' style={{'background' : '#f3f5f6'}}>
                                                                                <label style={{'width' : '100%', 'fontWeight' : 'bold', 'textAlign' : 'left'}}>Quantity : </label>
                                                                                <div class="qtySelector product-quantity">
                                                                                    <span class="decreaseQty" style={{'width': '30px', 'height': '30px', 'lineHeight': '30px'}} onClick={()=>{this.changeQuantity(0, product.id)}}><i class="fas fa-minus"></i></span>
                                                                                    <input type="text" style={{'height' : '30px'}} class="form-control qtyValue cart-amount" value={product.quantity}/>
                                                                                    <span class="increaseQty"  style={{'width': '30px', 'height': '30px', 'lineHeight': '30px'}} onClick={()=>{this.changeQuantity(1, product.id)}}><i class="fas fa-plus"></i></span>
                                                                                </div>
                                                                                
                                                                                {(product.quantity > product.max_quantity && product.max_quantity != 0) &&
                                                                                    <small style={{'color' : 'red', marginTop:'10px', 'textAlign' : 'center'}}>Only {product.max_quantity} left</small>
                                                                                }
                                                                               
                                                                                {(product.max_quantity == 0) &&
                                                                                    <small style={{'color' : 'red', marginTop:'10px', 'textAlign' : 'center'}}>Out of stock</small>
                                                                                }
                                                                               
                                                                            </div>
                                                                            
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    <div style={{'width' : '100%'}} className='my-2 w-100 justify-content-start d-flex align-items-center flex-row'>
                                                                        {product.discount &&
                                                                            <div style={{'margin' : '10px 0px', fontSize:'12px', textDecorationLine: 'line-through'}}>₹ {product.price}</div>
                                                                        }
                                                                        {!product.discount &&
                                                                            <div style={{'margin' : '10px 0px', fontSize:'12px'}}>₹ {product.price}</div>
                                                                        }
                                                                        {product.discount &&
                                                                            <div style={{'padding': '3px 10px', fontSize:'12px', 'background': 'limegreen', fontWeight:'bold', 'margin': '10px', 'borderRadius': '10px'}}>{product.discount} % OFF </div>  
                                                                        }
                                                                        {product.discount &&
                                                                            <div style={{'margin' : '10px 0px', fontSize:'12px'}}>₹ {(product.price - product.price * product.discount /100).toFixed(0)}</div> 
                                                                        }
                                                                        
                                                                        <div style={{'color' : '#FF6A00', fontSize:'12px', marginLeft:'13%'}} onClick={()=>{this.removeProduct(product.id)}} class="remove-from-cart">Remove</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            )}
                                        </div>
                                    </div>
                                    
                                    {this.state.coupons.length > 0 &&
                                        <div class="card mt-4">
                                            {(this.state.total_price - this.state.total_discount) >= this.state.coupon_discount_min_price &&
                                                <div className="card-body justify-content-around d-flex align-items-center">
                                                    <div className="justify-content-center d-flex align-items-center">
                                                        <img src="assets/images/discount.png" alt="LOGO" style={{width:'50px'}}/>
                                                        {Object.keys(this.state.coupon).length == 0 &&
                                                            <p class="product-title mx-2">Apply Coupon</p>
                                                        }
            
                                                        {Object.keys(this.state.coupon).length > 0 &&
                                                            <p class="product-title mx-2" style={{'fontWeight' : 'bold'}}>{this.state.coupon.code}</p>
                                                        }
                                                        
                                                    </div>
                                                    <div>
                                                        {Object.keys(this.state.coupon).length == 0 &&
                                                            <a class="btn btn-primary" onClick={()=>{this.setState({'show_coupons' : true})}} href="javascript:;"><span>Select Coupon</span></a>
                                                        }
                                                        {Object.keys(this.state.coupon).length > 0 &&
                                                            <a class="btn btn-primary" onClick={()=>{this.removeCoupon()}} href="javascript:;"><span>Remove</span></a>
                                                        }
                                                    </div>
                                                </div>
                                            }
                                            {/* {(this.state.total_price - this.state.total_discount) < this.state.coupon_discount_min_price &&
                                                <div className="card-body justify-content-around d-flex align-items-center">
                                                    <div className="justify-content-start d-flex align-items-center flex-row" style={{'width' : "100%"}}>
                                                        <i class="fa fa-info-circle" style={{'fontSize' : 'xx-large'}} aria-hidden="true"></i>
                                                        <p className='mx-3'>Add products worth ₹ {(this.state.coupon_discount_min_price - this.state.total_price + this.state.total_discount).toFixed(0)} to apply coupon on cart value amount above ₹ {this.state.coupon_discount_min_price}.</p>
                                                    </div>
                                                </div>
                                            } */}

                                            {(this.state.total_price - this.state.total_discount - this.state.coupon_discount) < this.state.shipping_discount_min_price &&
                                                <div className="card-body justify-content-around d-flex align-items-center">
                                                    <div className="justify-content-start d-flex align-items-center flex-row" style={{'width' : "100%"}}>
                                                        <i class="fa fa-info-circle" style={{'fontSize' : 'xx-large'}} aria-hidden="true"></i>
                                                        <p className='mx-3'>Add products worth ₹ {this.state.shipping_discount_min_price - (this.state.total_price - this.state.total_discount - this.state.coupon_discount).toFixed(0)} to get free shipping on cart value amount above ₹ {this.state.shipping_discount_min_price}.</p>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    }
                                    
    
                                    <div class="card mt-4">
                                        <div class="card-body">
                                            <div class="shopping-cart-footer">
                                                <h5 style={{'textAlign' : 'left'}}>Order Bill</h5>
                                                <div className='justify-content-end d-flex align-items-end'>
                                                    <table className='table bill-table table-bordered w-50'>
                                                        <tbody>
                                                            <tr>
                                                                <th style={{'textAlign' : 'left'}}>MRP Total</th>
                                                                <td style={{'textAlign' : 'right'}}>₹ {this.state.total_price.toFixed(0)}</td>
                                                            </tr>
                                                            <tr>
                                                                <th style={{'textAlign' : 'left'}}>Discount</th>
                                                                <td style={{'textAlign' : 'right'}}>-₹ {this.state.total_discount.toFixed(0)}</td>
                                                            </tr>
                                                            {this.state.coupon_discount > 0 &&
                                                                <tr>
                                                                    <th style={{'textAlign' : 'left'}}>Coupon Discount</th>
                                                                    <td style={{'textAlign' : 'right'}}>-₹ {this.state.coupon_discount.toFixed(0)}</td>
                                                                </tr>
                                                            }
                                                            <tr>
                                                                <th style={{'textAlign' : 'left'}}>Shipping Charge</th>
                                                                <td style={{'textAlign' : 'right'}}>
                                                                    {this.state.total_price - this.state.total_discount - this.state.coupon_discount < this.state.shipping_discount_min_price &&
                                                                        <>
                                                                            ₹ {this.state.shipping_charge}
                                                                        </>
                                                                    }
                                                                    {this.state.total_price - this.state.total_discount - this.state.coupon_discount >= this.state.shipping_discount_min_price &&
                                                                        <div style={{'justifyContent' : 'flex-end', 'display' : 'flex', 'flexDirection' : 'row', 'alignItems' : 'center'}}>
                                                                            <span style={{'textDecorationLine' : 'line-through'}} className='mx-2'>₹ {this.state.shipping_charge}</span>
                                                                            <span style={{color :'#2ec500'}} className='ml-2'>FREE</span>
                                                                        </div>
                                                                        
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th style={{'textAlign' : 'left'}}>To Pay</th>
                                                                <td style={{'textAlign' : 'right'}}>
                                                                    {this.state.total_price - this.state.total_discount - this.state.coupon_discount < this.state.shipping_discount_min_price &&
                                                                        <span style={{'fontWeight' : "bold"}}>₹ {(this.state.total_price - this.state.total_discount - this.state.coupon_discount + this.state.shipping_charge).toFixed(0)}</span>
                                                                    }
    
                                                                    {this.state.total_price - this.state.total_discount - this.state.coupon_discount >= this.state.shipping_discount_min_price &&
                                                                        <span style={{'fontWeight' : "bold"}}>₹ {(this.state.total_price - this.state.total_discount - this.state.coupon_discount).toFixed(0)}</span>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                        
                                                    </table>
                                                </div>
                                            </div>
                                            <div class="shopping-cart-footer">
                                                <div class="column"><a class="btn btn-primary" href="javascript:;" onClick={()=>{this.checkStock()}} ><span>Checkout</span></a></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        
                        {this.state.cart.length == 0 &&
                            <div class="container padding-bottom-3x mb-1">
                                <div id="view_cart_load">
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div class="card">
                                                <div class="card-body">
                                                    <img src="assets/images/cart.png" alt="LOGO" style={{"width" : "30%"}}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </>
                }
               
                {this.props.is_logged_in == false &&
                    <div class="container padding-bottom-3x mb-1">
                        <div id="view_cart_load">
                            <div className='row'>
                                <div className='col-12'>
                                    <div class="card">
                                        <div class="card-body">
                                            <img src="assets/images/cart.png" alt="LOGO" style={{"width" : "30%"}}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                
            </>
        )
    }
}



export default withRouter(Cart);


