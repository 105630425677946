import React from 'react';
import * as ReactDOM from 'react-dom';
import Api from '../../Services/api';
import Login from '../auth/Login';
import withRouter from '../../Services/withRouter';
import { Link } from 'react-router-dom';
import SabpaisaSdk from '../../SabpaisaSdk.tsx';
import { createRoot } from 'react-dom/client';
import queryString from 'query-string';
import Addresses from '../addresses/Addresses';
import NewAddress from '../addresses/NewAddress.js';
import EditAddress from '../addresses/EditAddress.js';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


class Checkout extends React.Component{


    componentDidMount = async()=>{
        await this.getCart();
        await this.getAddresses();
    }


   

    constructor(props){
        super(props);
        this.state = {
            'coupon' : {},
            'cart' : [],
            'total_amount' : 0,
            'total_discount' : 0,
            'coupon_discount' : 0,
            'total_price' : 0,
            'shipping_charge' : 0,
            'shipping_discount_min_price' : 0,
            'gift_charge_min_price' : 0,
            'addresses' : [],
            'address' : {},
            'order_id' : "",
            'amount' : "",
            'is_open' : false,
            'show_addresses' : false,
            'show_new_address' : false,
            'gift' : false,
            'gift_message' : "",
            'gift_from' : "",
            'gift_charge' : 0,
            'selected_delivery_address' : "",
            'edit_address' : {},
            'show_edit_address' : false,
            'payment_mode' : 0,
            'pincode_error' : false
        }
    }


    getCart = async()=>{
        var response = await Api.GetRequest({}, 'cart');
        if(response.status == 200){
            this.setState({
                'cart' : response.cart,
                'total_discount' : response.total_discount,
                'total_amount' : response.total_amount,
                'total_price' : response.total_price,
                'coupon' : response.coupon,
                'shipping_charge' : response.shipping_charge,
                'shipping_discount_min_price' : response.shipping_discount_min_price,
                'gift_charge_min_price' : response.gift_charge_min_price
            }, ()=>{
                this.calculateCouponDiscount();
            });
            
        }
        else{
            toast.error("Something went wrong. Please try again later.");
        }
    }



    getAddresses = async()=>{
        this.setState({'address' : {}})
        var response = await Api.GetRequest({}, 'addresses')
        if(response.status == 200){
            this.setState({'addresses' : response.addresses}, ()=>{
                this.setDeliveryAddress();
            });
        }
        else{
            toast.error("Something went wrong. Please try again later.")
        }
    }



    setDeliveryAddress = async()=>{
        // if(this.state.selected_delivery_address){
        //     var selected_index = await this.state.addresses.findIndex((address)=>{return (address.id == this.state.selected_delivery_address)});
        //     if(selected_index > -1){
        //         console.log("----------------");
        //         console.log(selected_index)
        //         await this.setState({
        //             'address' : this.state.addresses[selected_index]
        //         });
        //         // console.log(this.state.address)
        //     }
        // }
        // else{
            var delivery_index = await this.state.addresses.findIndex((address)=>{return address.is_delivery_address == 1});
            if(delivery_index > -1){
                this.setState({
                    'address' : this.state.addresses[delivery_index],
                    'selected_delivery_address' : this.state.addresses[delivery_index].id
                }, ()=>{
                    this.checkDeliveryAddress()
                });
            }
            else{
                var default_index = await this.state.addresses.findIndex((address)=>{return address.is_default == 1});
                if(default_index > -1){
                    this.setState({
                        'address' : this.state.addresses[default_index],
                        'selected_delivery_address' : this.state.addresses[default_index].id
                    }, ()=>{
                        this.checkDeliveryAddress()
                    });
                }
                else{
                    if(this.state.addresses.length > 0){
                        this.setState({
                            'address' : this.state.addresses[0],
                            'selected_delivery_address' : this.state.addresses[0].id
                        }, ()=>{
                            this.checkDeliveryAddress()
                        });
                    }
                }
            }
        // }

       
       
    }




    checkDeliveryAddress = async() =>{
        if(Object.keys(this.state.address).length > 0){
            var data = {
                'pincode' : this.state.address.zipcode,
                'payment_mode' : this.state.payment_mode
            }
            console.log(data)
            var response = await Api.PostRequest(data, 'check-availability');
            if(response.status == 200){
                this.setState({'pincode_error' : false});
            }
            else{
                this.setState({'pincode_error' : true});
            }
        }
        else{

        }
    }
    

    

    verifyUser = async()=>{
        var response = await Api.GetRequest({}, 'verify-user');
        if(response.status == 401){
            toast.error("You have to login first.")
            this.props.navigate("/home");
        }
    }


    validateForm = async()=>{
        this.createOrder();
    }



    showAddresses = async()=>{
        await this.getAddresses();
        this.setState({'show_addresses' : true})
    }


    handleAddress = async(address)=>{
        if(Object.keys(address).length > 0){
            this.setState({
               "address" : address
            })
        }
        this.setState({'show_addresses' :  false});
    }


    addNewAddress = async()=>{
        this.setState({'show_new_address' : true});
      
    }


    handleNewAddress = async()=>{
        await this.getAddresses();
        this.setState({'show_new_address' :  false});
    }



    calculateCouponDiscount = async()=>{
        var discount = 0;
        if(Object.keys(this.state.coupon).length > 0){
            if(this.state.coupon.discount_type == 0){
                discount = this.state.coupon.discount; 
            }
            else{
                discount = this.state.total_amount * this.state.coupon.discount / 100;    
            }
        }
        else{
            discount = 0;
        }
        this.setState({'coupon_discount' : discount});
    } 



    setGift = async()=>{
        if(this.state.gift == true){
            this.setState({'gift' : false, gift_charge : 0});
        }
        else{
            this.setState({'gift' : true, gift_charge : this.state.gift_charge_min_price})
        }
    }


    checkGift= async() =>{
        if(this.state.gift){
            if(this.state.gift_from.trim().length == 0){
                toast.error("Please enter gift from name.");
            }
            else if(this.state.gift_message.trim().length == 0){
                toast.error("Please enter gift message.")   
            }
            else{
                this.checkStock();
            }
        }
        else{
            this.checkStock();
        }
        
    }



    checkStock = async() =>{
        if(Object.keys(this.state.address).length == 0){
            toast.error("Please select delivery address.")
        }
        else{
            var response = await Api.GetRequest({}, 'check-stock');
            if(response.status == 200){
                this.generateOrder();
            }
            else if(response.status == 406){
                this.setState({'stock_error' : true});
            }
            else{
                toast.error(response.message)
            }
        }   
       
    }


    generateOrder = async()=>{
        var data = {
            'gift' : this.state.gift ? 1 : 0
        }
        var response = await Api.GetRequest(data, 'create-order');
        if(response.status == 200){
            if(this.state.payment_mode == 0){
                this.makePayment(response.cart_items, response.order, response.shipping_charge, response.gift_charge, response.coupon, response.coupon_discount)
            }
            else{
                this.placeOfflineOrder(response.cart_items, response.shipping_charge, response.gift_charge, response.coupon, response.coupon_discount)
            }
            
        }
    }

    
    makePayment = async(cart, order, shipping_charge, gift_charge, coupon,  coupon_discount)=>{

        var options = {
            description: 'Credits towards consultation',
            image: 'https://i.imgur.com/3g7nmJC.jpg',
            currency: 'INR',
            key: process.env.REACT_APP_RAZORPAY_KEY_ID,
            amount: order.amount.toString(),
            name: process.env.REACT_APP_APP_NAME,
            order_id: order.id,
            prefill: {
              email: '',
              contact: '',
              name: ''
            },
            theme: {color: '#000000'},
            handler: async (data) => {
                try {
                    this.verifyPayment(data, cart, shipping_charge, gift_charge, coupon, coupon_discount);
                } catch (err) {
                  console.log(err);
                }
            },
        }
        const rzp1 = new window.Razorpay(options);
        rzp1.open();
    }


    verifyPayment = async(payment_data, cart, shipping_charge, gift_charge, coupon, coupon_discount)=>{
        var response = await Api.PostRequest(payment_data, 'payment-verification');
        if(response.status == 200){
            this.placeOrder(cart, response.transaction_id, shipping_charge, gift_charge, coupon, coupon_discount)
        }
        else{
            toast.success("Payment not verify. Please contact to admin.");   
        }
    }

    placeOrder = async(cart, transaction_id, shipping_charge, gift_charge, coupon, coupon_discount)=>{
        var data = {
            'name' : this.state.address.name,
            'email' : this.state.address.email,
            'address' : this.state.address.address,
            'zipcode' : this.state.address.zipcode,
            'mobile' : this.state.address.phone,
            'city' : this.state.address.city, 
            'state' : this.state.address.state,
            'transaction_id' : transaction_id,
            'cart' : cart,
            'address_id' : this.state.address.id,
            'gift' : this.state.gift == true? 1: 0,
            'gift_message' : this.state.gift_message,
            'gift_from' : this.state.gift_from,
            'gift_charge' : gift_charge,
            'shipping_charge' : shipping_charge,
            'coupon_id' : "",
            'coupon_discount' : 0,
            'coupon_code' : "",
            'payment_status' : 1,
            'payment_mode' : 0
        }

        if(Object.keys(coupon).length > 0){
            data.coupon_id = coupon.id;
            data.coupon_discount = coupon_discount;
            data.coupon_code = coupon.code;
        }

        var response = await Api.PostRequest(data, 'place-order');
        if(response.status == 200){
            toast.success("Order placed successfully.");
            await this.setState({
                'cart' : [],
                'coupon' : {},
                'total_amount' : 0,
                'total_price' : 0,
                'total_discount' : 0,
                'coupon_discount' : 0,
                'coupon' : {},
                'addresses' : [],
                'address' : {},
                'order_id' : "",
                'amount' : "",
                'is_open' : false,
                'show_address' : false,
                'shipping_charge' : 0,
                'shipping_discount_min_price' : 0,
                'payment_mode' : 0

            });
            this.props.navigate('/home')
        }
        else{
            toast.error("Something went wrong. Please try again later.")
        }
    }



    placeOfflineOrder = async(cart, shipping_charge, gift_charge, coupon, coupon_discount)=>{
        var data = {
            'name' : this.state.address.name,
            'email' : this.state.address.email,
            'address' : this.state.address.address,
            'zipcode' : this.state.address.zipcode,
            'mobile' : this.state.address.phone,
            'city' : this.state.address.city, 
            'state' : this.state.address.state,
            'transaction_id' : "",
            'cart' : cart,
            'address_id' : this.state.address.id,
            'gift' : this.state.gift == true? 1: 0,
            'gift_message' : this.state.gift_message,
            'gift_from' : this.state.gift_from,
            'gift_charge' : gift_charge,
            'shipping_charge' : shipping_charge,
            'coupon_id' : "",
            'coupon_discount' : 0,
            'coupon_code' : "",
            'payment_status' : 0,
            'payment_mode' : 1
        }

        if(Object.keys(coupon).length > 0){
            data.coupon_id = coupon.id;
            data.coupon_discount = coupon_discount;
            data.coupon_code = coupon.code;
        }

        var response = await Api.PostRequest(data, 'place-order');
        if(response.status == 200){
            toast.success("Order placed successfully.");
            await this.setState({
                'cart' : [],
                'coupon' : {},
                'total_amount' : 0,
                'total_price' : 0,
                'total_discount' : 0,
                'coupon_discount' : 0,
                'coupon' : {},
                'addresses' : [],
                'address' : {},
                'order_id' : "",
                'amount' : "",
                'is_open' : false,
                'show_address' : false,
                'shipping_charge' : 0,
                'shipping_discount_min_price' : 0,
                'payment_mode' : 0
            });
            this.props.navigate('/home')
        }
        else{
            toast.error("Something went wrong. Please try again later.")
        }
    }




    editAddress = async(address)=>{
        this.setState({
            'edit_address' : address,
            'show_edit_address' : true
        });
    }


    handleEditAddress = async()=>{
        await this.getAddresses();
        this.setState({'show_edit_address' :  false});
    }


    setPaymentMode = async(payment_mode) =>{
        this.setState({'payment_mode' : payment_mode},()=>{
            this.checkDeliveryAddress();
        });

    }


    render() {
        return(
            <>  
                <Addresses show_addresses={this.state.show_addresses} editAddress={this.editAddress} getAddresses={this.getAddresses} selected_delivery_address={this.state.selected_delivery_address} addresses={this.state.addresses} handleAddress={this.handleAddress} addNewAddress={this.addNewAddress}></Addresses>
                <NewAddress show_new_address={this.state.show_new_address} handleNewAddress={this.handleNewAddress}></NewAddress>
                <EditAddress show_edit_address={this.state.show_edit_address} address={this.state.edit_address} handleEditAddress={this.handleEditAddress}></EditAddress>
                
                <div class="page-title">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <ul class="breadcrumbs">
                                    <li><Link to="/">Home</Link> </li>
                                    <li class="separator"></li>
                                    <li>Cart</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container padding-bottom-3x mb-1 checkut-page">
                    <div class="row">
                        <div class="col-xl-9 col-lg-4">

                            

                            <div class="card">
                                <div class="card-body">
                                    <div className='justify-content-between d-flex align-items-center flex-row'>
                                        <h6 style={{'textAlign' : 'left'}}>Billing Address</h6>
                                        <button onClick={()=>{this.showAddresses()}} id="continue__button" class="btn btn-primary  btn-sm" type="button"><i class="icon-home"></i> <span class="hidden-xs-down ml-5">Change</span></button>                                        
                                    </div>
                                    
                                    {Object.keys(this.state.address).length > 0 &&
                                        <div style={{'border' : '1px dashed #000000', cursor : "pointer"}} className="card w-100 justify-content-start d-flex align-items-start my-2 px-3 py-3 mt-5" >
                                            <p className="mb-0">{this.state.address.name}</p>
                                            <p className="mb-0">{this.state.address.phone}</p>
                                            <p className="mb-0">{this.state.address.address}</p>
                                            <p className="mb-0">{this.state.address.city}, {this.state.address.zipcode}</p>
                                        </div>
                                    }

                                    {Object.keys(this.state.address).length == 0 &&
                                        <div style={{'border' : '1px dashed #000000', cursor : "pointer"}} className="card w-100 justify-content-center d-flex align-items-center my-2 px-3 py-3 mt-5" >
                                            <div>
                                                <img src="assets/images/no_address.png" alt="LOGO" style={{"width" : "70%"}}/>
                                            </div>
                                        </div>
                                    }

                                    {this.state.pincode_error &&
                                        <>
                                            {this.state.payment_mode == 0 &&
                                                <small style={{'color' : 'red', 'textAlign' : 'left', 'width' : '100%'}}>Delivery not available for this pincode.</small>
                                            }

                                            {this.state.payment_mode == 1 &&
                                                <small style={{'color' : 'red', 'textAlign' : 'left', 'width' : '100%'}}>Cash on delivery not available for this pincode.</small>
                                            }
                                            
                                        </>
                                        
                                    }  
                                    
                                </div>
                            </div>


                            <div className='card mt-2 py-3'>
                                <div className='justify-content-around d-flex align-items-center'>
                                    <div style={{'width' : '50%', 'textAlign' : 'center', 'display' : 'flex', 'flexDirection' : 'row'}}>
                                        
                                        <div class="custom-control custom-checkbox" style={{'textAlign' : 'center', 'justifyContent':'center', 'alignItems':'center', 'width':'100%'}}>
                                            <label style={{'width' : '50%', 'textAlign' : 'center'}}>Cash on Delivery</label>
                                            <input class="custom-control-input option mx-5" name="size" value={this.state.payment_mode} onChange={(e)=>{this.setPaymentMode(1)}} checked={this.state.payment_mode == 1}  type="radio" id="2Black"/>
                                        </div> 
                                    </div>

                                    <div style={{'width' : '50%', 'textAlign' : 'center', 'display' : 'flex', 'flexDirection' : 'row'}}>
                                        
                                        <div class="custom-control custom-checkbox" style={{'textAlign' : 'center', 'justifyContent':'center', 'alignItems':'center', 'width':'100%'}}>
                                            <label style={{'width' : '50%', 'textAlign' : 'center'}}>Online</label>
                                            <input class="custom-control-input option" name="size" value={this.state.payment_mode} onChange={(e)=>{this.setPaymentMode(0)}} checked={this.state.payment_mode == 0}  type="radio" id="2Black"/>
                                        </div> 
                                    </div>
                                </div>
                                
                            </div>


                            <div className='card mt-2'>
                                <div className='justify-content-around d-flex align-items-center'>
                                    <div style={{'justifyContent' : 'flex-start', 'display' : 'flex', 'alignItems' : 'center', 'flexDirection' : 'row'}}>
                                        <img src='assets/images/gift.png' class="px-2 py-4" style={{'width' : '75px'}}/>
                                        <div style={{'justifyContent' : 'center', 'display' : 'flex', 'alignItems' : 'center', 'flexDirection' : "column"}}>
                                            <span style={{'fontWeight' : 'bold'}}>Send As gift</span>
                                            <small>(Additional Rs. 60/-)</small>
                                        </div>
                                    </div>
                                
                                    <div class="custom-control custom-checkbox" style={{'textAlign' : 'left'}}>
                                        <input class="custom-control-input option" name="size" onChange={(e)=>{this.setGift()}}  type="checkbox" id="2Black"/>
                                    </div>   
                                </div>
                            </div>
                            
                            {this.state.gift &&
                                <div className='card px-4 py-4 mt-2'>
                                    <div className="input-group justify-content-start d-flex align-items-center">
                                        <label>Gift From</label>
                                        <input onChange={(event) => {this.setState({"gift_from" : event.target.value})}} className="form-control" type="text" name="gift_name" placeholder="Enter name"/>
                                    </div>

                                    <div className="input-group justify-content-start d-flex align-items-center my-4">
                                        <label>Gift Message</label>
                                        <textarea rows={10} onChange={(event) => {this.setState({"gift_message" : event.target.value})}} className="form-control" type="text" name="gift_message" placeholder="Enter message"/>
                                    </div>
                                </div>
                            }
                            
                            
                        </div>

                        
                        <div class="col-xl-3 col-lg-4">
                            <aside class="sidebar">
                            <div class="padding-top-2x hidden-lg-up"></div>
                                <section class="card widget widget-featured-posts widget-order-summary p-4">
                                    <h3 class="widget-title">Order Summary</h3>
                                                          
                                    <table class="table">
                                        <tbody>
                                            <tr>
                                                <td style={{'textAlign' : 'left'}}>MRP Total:</td>
                                                <td class="text-gray-dark">₹ {this.state.total_price.toFixed(0)}</td>
                                            </tr>

                                            <tr>
                                                <td style={{'textAlign' : 'left'}}>Discount:</td>
                                                <td class="text-gray-dark">-₹ {this.state.total_discount.toFixed(0)}</td>
                                            </tr>

                                            {this.state.coupon_discount > 0 &&
                                                <tr>
                                                    <td style={{'textAlign' : 'left'}}>Coupon Discount</td>
                                                    <td class="text-gray-dark">-₹ {this.state.coupon_discount.toFixed(0)}</td>
                                                </tr>
                                            }

                                            <tr>
                                                <td style={{'textAlign' : 'left'}}>Shipping Charges:</td>
                                                <td>
                                                    {this.state.total_price - this.state.total_discount - this.state.coupon_discount < this.state.shipping_discount_min_price &&
                                                        <>
                                                            ₹ {this.state.shipping_charge}
                                                        </>
                                                    }
                                                    {this.state.total_price - this.state.total_discount - this.state.coupon_discount >= this.state.shipping_discount_min_price &&
                                                        <div style={{'justifyContent' : 'flex-end', 'display' : 'flex', 'flexDirection' : 'row', 'alignItems' : 'center'}}>
                                                            <span style={{'textDecorationLine' : 'line-through'}} className='mx-2'>₹ {this.state.shipping_charge}</span>
                                                            <span style={{color :'#2ec500'}} className='ml-2'>FREE</span>
                                                        </div>
                                                        
                                                    }
                                                </td>
                                            </tr>
                                            
                                            <tr>
                                                <td style={{'textAlign' : 'left'}} class="text-lg text-primary">To Pay</td>
                                                <td class="text-lg text-primary grand_total_set">
                                                    {this.state.total_price - this.state.total_discount - this.state.coupon_discount < this.state.shipping_discount_min_price &&
                                                        <span style={{'fontWeight' : "bold"}}>₹ {(this.state.total_price - this.state.total_discount - this.state.coupon_discount + this.state.shipping_charge + this.state.gift_charge).toFixed(0)}</span>
                                                    }

                                                    {this.state.total_price - this.state.total_discount - this.state.coupon_discount >= this.state.shipping_discount_min_price &&
                                                        <span style={{'fontWeight' : "bold"}}>₹ {(this.state.total_price - this.state.total_discount - this.state.coupon_discount + this.state.gift_charge).toFixed(0)}</span>
                                                    }
                                                </td>
                                            </tr>

                                        
                                        </tbody>
                                    </table>
                                    {this.state.payment_mode == 0 &&
                                        <button onClick={()=>{this.checkGift()}} id="continue__button" class="btn btn-primary  btn-sm" type="button"><span>Procceed To Pay</span></button>  
                                    }
                                    {this.state.payment_mode == 1 &&
                                        <button onClick={()=>{this.checkGift()}} id="continue__button" class="btn btn-primary  btn-sm" type="button"><span>Place Order</span></button>  
                                    }
                                                                          
                                </section>
                            </aside>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}



export default withRouter(Checkout);


